import React, { useEffect, useState } from 'react';
import MaterialTable from '@material-table/core';
import { CircularProgress } from '@mui/material';
import firebase from 'firebase/app';
import 'firebase/database';
import { ExportCsv } from "@material-table/exporters";

const ViewRegisterStudentData = () => {
  const [registeredStudents, setRegisteredStudents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStudents = async () => {
      const schoolsRef = firebase.database().ref('/SchoolFairs');
      schoolsRef.on('value', (snapshot) => {
        const fairsData = snapshot.val();
        console.log("Fetched fairs data:", fairsData);
  
        if (fairsData) {
          const allStudents = [];
  
          Object.keys(fairsData).forEach(fairId => {
            const fair = fairsData[fairId];
  
            Object.keys(fair).forEach(subFairId => {
              const studentsRef = fair[subFairId].students; 
              const schoolName = fair[subFairId].displayName;
              
              if (studentsRef) {
                console.log("Fair ID:", fairId, "Sub Fair ID:", subFairId, "Students:", studentsRef);
  
                Object.keys(studentsRef).forEach(studentID => {
                  const student = studentsRef[studentID];
  
                  allStudents.push({
                    studentID: studentID,
                    age: student.age,
                    areaOfInterest: student.areaOfInterest,
                    email: student.email,
                    gender: student.gender,
                    name: student.name,
                    nationality: student.nationality,
                    phoneNumber: student.phoneNumber,
                    timestamp: student.timestamp,
                    schoolName: schoolName, 
                  });
                });
              }
            });
          });
  
          if (allStudents.length > 0) {
            console.log("All Fetched Students:", allStudents);
            setRegisteredStudents(allStudents);
          } else {
            console.warn("No students found in any fairs.");
            setRegisteredStudents([]);
          }
        } else {
          console.warn("No fairs data found.");
          setRegisteredStudents([]);
        }
        setLoading(false);
      }, (error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  
      return () => schoolsRef.off('value');
    };
  
    fetchStudents();
  }, []);

  return (
    <div style={{ maxWidth: '100%' }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <MaterialTable
          title="Registered Students"
          columns={[
            { title: '#', render: rowData => rowData.tableData.id + 1 },
            { title: 'School Name', field: 'schoolName' }, 

            { title: 'Name', field: 'name' },
            { title: 'Email', field: 'email' },
            { title: 'Phone', field: 'phoneNumber' },
            { title: 'Age', field: 'age' },
            { title: 'Gender', field: 'gender' },
            { title: 'Area Of Interest', field: 'areaOfInterest' },
            { title: 'Nationality', field: 'nationality' },
            { title: 'Timestamp', field: 'timestamp' },
          ]}
          data={registeredStudents}
          options={{
            detailPanelColumnAlignment: 'right',
            filtering: false,
            sorting: true,
            exportAllData: true,
            exportMenu: [
              {
                label: 'Export Events Data In CSV',
                exportFunc: (cols, data) => ExportCsv(cols, data),
              },
            ],
            actionsColumnIndex: -1,
          }}
          style={{
            boxShadow: '#6a1b9a3d 0px 8px 16px 0px',
            borderRadius: '8px',
          }}
        />
      )}
    </div>
  );
};

export default ViewRegisterStudentData;
